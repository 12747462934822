@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

body {
  margin: 0;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
    font-family: 'VT323', monospace;
    color: #FF6700;
    font-size: 22px;
}
