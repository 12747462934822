.App {
  text-align: center;
}

.grid {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.row, .block {
    display: flex;
    justify-content: center;
    margin: 3px;
}

.block {
    font-size: 40px;
    width: 56px;
    height: 51px;
    border: 1px solid;
    border-radius: 6px;
    padding-top: 8px;
    transition: color 0.2s ease;
}

.block.hit {
    background-color: #1AFE49;
    color: black;
}

.block.partial {
    color: #FBF665;
}

.block.miss {
    color: #575267;
}

.block.wrong {
    color: #de004e;
}

.keyboard {
    text-align: center;
    margin-top: 5px;
}

.keyboardRow {
    display: flex;
    justify-content: center;
}

.keyboardKey {
    font-size: 30px;
    padding: 5px 0;
    margin: 2px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid;
    border-radius: 6px;
    width: 50px;
    transition: color 0.2s ease-in-out;
}

.keyboardRow.controlRow {
    margin-top: 18px;
}

.keyboardKey.controlKey {
    width: 100px;
}

.keyboardKey.hit {
    background-color: #1AFE49;
    color: black;
}

.keyboardKey.partial {
    color: #FBF665;
}

.keyboardKey.miss {
    color: #575267;
}

.gameResult {
    margin-top: 8px;
}

.history {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.historyRow {
    display: flex;
    font-size: 30px;
}

.historyRow > code {
    padding: 3px;
}

.historyRow > code.unplayed {
    font-style: italic;
    color: grey;
}

.CorrectGuesses {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.CorrectGuesses code {
    font-size: 40px;
    filter: brightness(1.25);
}

.CorrectGuesses .dot {
    background-color: #FF6700;
    display: inline-block;
    border-radius: 30%;
    margin: 2px;
    width: 25px;
    height: 38px;
}

.CorrectGuesses .lastGuess div {
    display: inline-block;
    border-radius: 30%;
    margin: 2px;
    width: 25px;
    height: 38px;
}

.CorrectGuesses .lastGuess .hit {
    background-color: #1AFE49;
}

.CorrectGuesses .lastGuess .partial {
    background-color: #FBF665;
}

.CorrectGuesses .lastGuess .miss {
    background-color: #575267;
}

.CorrectGuesses code.incorrect {
    color: grey;
}

.CorrectGuesses code .hit {
    color: #1AFE49;
}

.CorrectGuesses code .partial {
    color: #FBF665;
}

.CorrectGuesses code .miss {
    color: #575267;
}
